import React from 'react'
import Layout from '../components/layout/layout'
import withI18next from "../components/utilities/withI18next"
import Toits from "../components/toits/toits"
import Hero from "../components/hero/hero"
import SEO from "../components/seo/seo"

const Page = props => {
  return (
    <>
      <SEO title={props.t("seoSolutionTitle")} desc={props.t("seoSolutionDescription")} />
      <Layout>
          <Hero image="houses"/>
          <Toits/>
      </Layout>
    </>
  )
}
export default withI18next({ ns: "common" })(Page);