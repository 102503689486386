import React, { useEffect } from "react";
import Image from "gatsby-image";
import { Link, StaticQuery } from "gatsby";
import { useTranslation } from "react-i18next";
import Fade from "react-reveal/Fade";
import Carousel from 'react-bootstrap/Carousel'

const QueryComp = () => {
  return (
    <StaticQuery
      query={graphql`
        query {
          companyInfo: allDataJson(filter: { id: { eq: "roofs" } }) {
            edges {
              node {
                sections {
                  id
                  title
                  description
                  widgetTitle
                  items {
                    title
                    id
                    id_link
                    description
                    text
                    images {
                      name
                      description
                    }
                  }
                }
              }
            }
          }
          images: allFile(filter: { relativePath: { glob: "toits/*.*" } }) {
            edges {
              node {
                relativePath
                name
                childImageSharp {
                  fluid(maxWidth: 1800, quality: 75) {
                    ...GatsbyImageSharpFluid
                    presentationWidth
                  }
                }
              }
            }
          }
        }
      `}
      render={data => <Roofs data={data} />}
    />
  );
};

const Roofs = ({ data }) => {
    let mainRoofs = data.companyInfo.edges[0].node.sections.find(
      section => section.id === "roofs"
    );

    let regulars = data.companyInfo.edges[0].node.sections[0].items.find(
        section => section.id === "regular"
    );

    let mansards = data.companyInfo.edges[0].node.sections[0].items.find(
      section => section.id === "mansarde"
    );

    let cathedrals = data.companyInfo.edges[0].node.sections[0].items.find(
      section => section.id === "cathedrale"
    );

    let images = data.images.edges;
    const { t } = useTranslation();

    function getRoofCarousel(sectionName){
      let section = data.companyInfo.edges[0].node.sections[0].items.find(
        section => section.id === sectionName
      );
      let comps = section.images.map(item => (
          <Carousel.Item key={item.name}>
            <Image fluid={images.find(img=>img.node.relativePath.search(item.name) > -1 ).node.childImageSharp.fluid} />
          </Carousel.Item>
      ))

      return comps
    }

    function getRoofDescriptions(sectionName){
      let section = data.companyInfo.edges[0].node.sections[0].items.find(
        section => section.id === sectionName
      );
      let comps = section.images.map(item => (
          <p key={item.name}>{t(item.description)}</p>
      ))

      return comps
    }

    function getRoofArticle(){
      let comps = mainRoofs.items.map(item => (
        <div key={item.id}>
            <div className="space" style={{marginTop:`60px`}}></div>
                  <div className="row">
                    <div className="col-md-8">
                      <Carousel interval={null}>
                         {getRoofCarousel(item.id)}
                      </Carousel>
                    </div>
                    <div className="col-md-4">
                      <div className="sticky-sidebar">
                          <h6 className="single-portfolio-title">{t(item.title)}</h6>
                          <p>{t(item.description)}</p>
                          {getRoofDescriptions(item.id)}
                      </div>
                    </div>
                  </div>
              </div>
      ))
      return comps
  }

  return (
      <section className="module">
          <div className="container">
              <div className="row">
                  <div className="col-md-12">
                      <h1><strong>{t(mainRoofs.title)}</strong></h1>
                      <p className="lead">{t(mainRoofs.description)}</p>
                  </div>
              </div>
              <div className="row">
                  <div className="col-md-12">
                      <div className="space" style={{marginTop:`60px`}}></div>
                      {getRoofArticle()}
                  </div>
              </div>  
          </div>
      </section>
      );
  }
export default QueryComp;